(function ($) {
    var $body = $('body');
    var $sections = $('.section');
    var $navigation = $('#navigation');

    /**
     * Plugin intialization
     */
    $('#pagepiling').pagepiling({
        direction: 'horizontal',
        menu: '#menu',
        anchors: $sections.map(function () {
            return $(this).attr('id');
        }),
        sectionsColor: ['#376e9f', 'white', 'white', '#376e9f', 'white', 'white', 'white'],
        navigation: false,
        afterRender: function () {
            addNecessaryBodyClasses($sections.eq(0));
        },
        afterLoad: function (anchorLink, index) {
            $navigation.find('.navigation__link').removeClass('navigation__link_active');
            if (index > 1) {
                $navigation.find('.navigation__link').eq(index - 2).addClass('navigation__link_active');
            }
            addNecessaryBodyClasses($sections.eq(index - 1));
        }
    });

    /**
     * Устанавливает нужные классы тегу body для переключения цветов логотипа и навигации
     *
     * @param $section
     */
    function addNecessaryBodyClasses($section) {
        var whiteLogoClass = 'set-white-logo',
            whiteNavClass = 'set-white-nav',
            whiteNavBgClass = 'set-white-nav-bg',
            showLabelsClass = 'show-nav-labels',
            whiteTitleClass = 'set-white-title',
            whiteHeaderClass = 'set-white-header';

        $body.removeClass([whiteLogoClass, whiteNavClass, whiteNavBgClass, showLabelsClass, whiteTitleClass, whiteHeaderClass].join(' '));
        if ($section.is('[data-white-logo]')) {
            $body.addClass(whiteLogoClass);
        }
        if ($section.is('[data-white-nav]')) {
            $body.addClass(whiteNavClass);
        }
        if ($section.is('[data-white-nav-bg]')) {
            $body.addClass(whiteNavBgClass);
        }
        if ($section.is('[data-show-labels]')) {
            $body.addClass(showLabelsClass);
        }
        if ($section.is('[data-white-title]')) {
            $body.addClass(whiteTitleClass);
        }
        if ($section.is('[data-white-header]')) {
            $body.addClass(whiteHeaderClass);
        }
    }
})(jQuery);
(function ($) {
    var $list = $('.js-area-list'),
        $map = $('.js-map'),
        $areas = $map.find('.area');

    // Клик по области
    $areas.on('click', function () {
        $areas.removeClass('area_active');
    });

    // Клик по ссылке из списка
    $list.find('a').on('click', function (event) {
        event.preventDefault();
        $areas.removeClass('area_active');
        var $activeArea = $map.find($(this).attr('href'));
        $activeArea.addClass('area_active');
        $('.js-geography-page').animate({
            scrollTop: $activeArea.position().top
        }, 400);
    });
})(jQuery);